import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import Link from 'next/link'
import Container from '@/components/basic/container'
import Header from '@/components/layout/header'

import menus from '@/constants/menus'
import InstallApp from '@/components/common/install-app'

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL

const Custom404 = () => {
  const router = useRouter()
  const [deepLink, setDeepLink] = useState()
  const [isDeepLink, setIsDeepLink] = useState(false)

  useEffect(() => {
    if (/^\/linking\-/.test(router?.asPath)) {
      setIsDeepLink(true)
      const deep_link_path = router?.asPath?.replace(/\/linking\-/, '')
      setDeepLink(`${BASE_URL}/redirect?path=${deep_link_path}`)
    }
  }, [router?.asPath, router?.pathname])

  return (
    <>
      <Head>
        <title>404 - Halaman Tidak Ditemukan</title>
        <meta key="bot:noindex" name="robots" content="noindex, nofollow" />
      </Head>

      <Header menus={menus} showAppLink={false} alert={false} />
      <Container size="normal">
        <main className="flex flex-row items-center justify-center min-w-screen min-h-500">
          <div className="w-full md:w-8/12 flex flex-col items-center justify-center text-center">
            <h1 className="font-gotham-medium font-bold text-2xl text-olive">404</h1>
            <p className="font-gotham-book font-bold text-sm md:text-base">
              Halaman yang kamu cari tidak dapat ditemukan..
            </p>
            {
              isDeepLink
                ? (
                  <div className="mt-4 py-4 md:py-8 flex flex-col items-center justify-center text-center">
                    <hr  />
                    <p className="font-gotham-book font-bold text-sm">
                      Buka link melalui smartphone kamu yang <br className="hidden md:block" />telah terpasang aplikasi PINA 
                    </p>
                    {
                      deepLink &&
                      <Link href={deepLink}>
                        <a className="mt-4 text-olive py-3 px-8 text-sm font-gotham-medium font-normal rounded-xl no-underline hover:opacity-90">
                          Buka Link
                        </a>
                      </Link>
                    }
                    <p className="font-gotham-book font-bold text-xs my-4">
                      atau
                    </p>
                    <p className="font-gotham-book font-bold text-sm">
                      Install terlebih dahulu melalui link berikut
                    </p>
                    <InstallApp className="mt-4" />
                  </div>
                )
                : (
                  <>
                    <Link href="/">
                      <a className="my-4 text-olive py-3 px-8 text-sm font-gotham-medium font-normal rounded-xl no-underline hover:opacity-90">
                        Kembali ke Home
                      </a>
                    </Link>
                  </>
                )
            }
          </div>
        </main>
      </Container>
      
    </>
  )
}

export default Custom404