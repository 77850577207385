import Link from 'next/link'
import Image from 'next/image'
import { DOWNLOAD_PINA_LINK } from '@/constants/links'

const InstallApp = ({ className = '' }) => {
  return (
    <div className={`w-auto flex flex-row flex-wrap -mx-1 md:-mx-2 ${className}`}>
      <Link href={DOWNLOAD_PINA_LINK} target="_blank">
        <a>
          <div className="mx-1 md:mx-2 w-28 md:w-36 h-auto relative">
            <Image
              priority={true}
              quality={100}
              src="https://pina-assets.s3.ap-southeast-1.amazonaws.com/icons/appstore-w256.png"
              alt="App Store"
              layout="responsive"
              width={256}
              height={74}
            />
          </div>
        </a>
      </Link>

      <Link href={DOWNLOAD_PINA_LINK} target="_blank">
        <a>
          <div className="mx-1 md:mx-2 w-28 md:w-36 h-auto relative">
            <Image
              priority={true}
              quality={100}
              src="https://pina-assets.s3.ap-southeast-1.amazonaws.com/icons/playstore-w256.png"
              alt="Play Store"
              layout="responsive"
              width={256}
              height={74}
            />
          </div>
        </a>
      </Link>
    </div>
  )
}

export default InstallApp